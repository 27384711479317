












































































import {Component, Vue} from 'vue-property-decorator';
import {PublicREREmployeeDTO} from "@/dto/request/rer/PublicREREmployeeDTO";
import {namespace} from "vuex-class";
import RERService from "@/services/request/RERService";
import {ResidencyStatus} from "@/constants/PersonConstants";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import {REREmployeeStatus} from "@/constants/request/RERConstants";

const AppModule = namespace("App");

@Component({
  components: {SingleFileHolder},
  computed: {
    REREmployeeStatus() {
      return REREmployeeStatus
    },
    ResidencyStatus() {
      return ResidencyStatus
    }
  }
})
export default class PublicEmployeeRER extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  request: PublicREREmployeeDTO | null = null;

  mounted(){
    this.loadRequest();
  }

  loadRequest(){
    this.startLoading();
    RERService.getEmployeeByPublicId(this.id).then(
        ok => {
          this.request = ok.data;
          this.stopLoading();
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    )
  }

  save(){
    if(!this.request){
      return;
    }
    this.startLoading();
    RERService.saveEmployeeByPublicId(this.id, this.request).then(
        ok => {
          this.stopLoading();
          this.loadRequest();
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    )
  }

  get id(){
    return this.$route.params.id;
  }

}
